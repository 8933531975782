var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "content_box beforetab",
          attrs: { id: "working-progress-box" },
        },
        [
          _c(
            "form",
            {
              attrs: { id: "searchForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("table", { staticClass: "tbl_search" }, [
                _vm._m(0),
                _c("tbody", [
                  _c("tr", [
                    _c("td", { staticClass: "pr5" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchParams.kind,
                              expression: "searchParams.kind",
                            },
                          ],
                          attrs: { id: "kind" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.searchParams,
                                  "kind",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.checkSearchValidation("kind")
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.004"))),
                          ]),
                          _vm._l(_vm.searchKindList, function (cvo, idx) {
                            return _c(
                              "option",
                              {
                                key: "kind" + idx,
                                domProps: { value: cvo.cd },
                              },
                              [_vm._v(_vm._s(cvo.cdNm))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("td", { staticClass: "text_left pl0" }, [
                      _vm.searchParams.kind == "BK" ||
                      _vm.searchParams.kind == "BL"
                        ? _c("div", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.searchParams.keyword,
                                  expression: "searchParams.keyword",
                                },
                              ],
                              attrs: {
                                type: "text",
                                id: "keyword",
                                placeholder: _vm.placeHolder,
                              },
                              domProps: { value: _vm.searchParams.keyword },
                              on: {
                                keyup: function ($event) {
                                  return _vm.checkSearchValidation("keyword")
                                },
                                keypress: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.search()
                                },
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.searchParams,
                                      "keyword",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    _vm.searchParams.keyword =
                                      _vm.searchParams.keyword.toUpperCase()
                                  },
                                ],
                              },
                            }),
                          ])
                        : _c(
                            "div",
                            { staticClass: "form_wrap t2" },
                            [
                              _c("e-date-range-picker-simple", {
                                attrs: {
                                  id: "fromDtToDt",
                                  sdate: _vm.searchParams.fromDt,
                                  edate: _vm.searchParams.toDt,
                                },
                                on: { change: _vm.changeDateRange },
                              }),
                            ],
                            1
                          ),
                    ]),
                    _c("td", [
                      _c("span", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchParams.profileYn,
                              expression: "searchParams.profileYn",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "profileYn_Y",
                            name: "profileYn",
                            value: "Y",
                          },
                          domProps: {
                            checked: _vm._q(_vm.searchParams.profileYn, "Y"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.searchParams,
                                "profileYn",
                                "Y"
                              )
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "mr10",
                            attrs: { for: "profileYn_Y" },
                          },
                          [
                            _c("span"),
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.006"))),
                          ]
                        ),
                        _c("span", { staticClass: "position_relative mr20" }, [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon help",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.showHelp01 = !_vm.showHelp01
                                },
                              },
                            },
                            [_vm._v("help")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showHelp01,
                                  expression: "showHelp01",
                                },
                              ],
                              staticClass:
                                "tooltip_wrap short position_absolute",
                              staticStyle: {
                                width: "180px",
                                left: "0",
                                top: "24px",
                              },
                            },
                            [
                              _c("div", { staticClass: "cont" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "close",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showHelp01 = false
                                      },
                                    },
                                  },
                                  [_vm._v("close")]
                                ),
                                _c("ul", { staticClass: "bul_list_sm t2" }, [
                                  _c("li", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("msg.ONEX010T010.007")
                                      ),
                                    },
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchParams.profileYn,
                              expression: "searchParams.profileYn",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "profileYn_N",
                            name: "profileYn",
                            value: "N",
                          },
                          domProps: {
                            checked: _vm._q(_vm.searchParams.profileYn, "N"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.searchParams,
                                "profileYn",
                                "N"
                              )
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "mr10",
                            attrs: { for: "profileYn_N" },
                          },
                          [
                            _c("span"),
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.008"))),
                          ]
                        ),
                        _c("span", { staticClass: "position_relative mr20" }, [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon help",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.showHelp02 = !_vm.showHelp02
                                },
                              },
                            },
                            [_vm._v("help")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showHelp02,
                                  expression: "showHelp02",
                                },
                              ],
                              staticClass:
                                "tooltip_wrap short position_absolute",
                              staticStyle: {
                                width: "200px",
                                left: "0",
                                top: "24px",
                              },
                            },
                            [
                              _c("div", { staticClass: "cont" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "close",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showHelp02 = false
                                      },
                                    },
                                  },
                                  [_vm._v("close")]
                                ),
                                _c("ul", { staticClass: "bul_list_sm t2" }, [
                                  _c("li", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("msg.ONEX010T010.009")
                                      ),
                                    },
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("td", { staticClass: "text_right" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button blue sh",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.search()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.010")))]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("div", { staticClass: "flex_box" }, [
          _c("span", { staticClass: "tot_num" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.011"))),
            _vm._v(" : "),
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.totalCount)),
            ]),
          ]),
          _vm.relYn
            ? _c("span", { staticClass: "input_box" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selPo,
                        expression: "selPo",
                      },
                    ],
                    staticClass: "wid100",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selPo = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "POL" } }, [
                      _vm._v("Departure"),
                    ]),
                    _c("option", { attrs: { value: "POD" } }, [
                      _vm._v("Arrival"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.relYn
            ? _c("span", { staticClass: "input_box" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selCtr,
                        expression: "selCtr",
                      },
                    ],
                    staticClass: "wid200",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selCtr = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.checkSelectCtr()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.004"))),
                    ]),
                    _vm._l(_vm.ctrCdList, function (ctrInfo) {
                      return _c(
                        "option",
                        {
                          key: ctrInfo.ctrCd,
                          domProps: { value: ctrInfo.ctrCd },
                        },
                        [_vm._v(_vm._s(ctrInfo.ctrEnm))]
                      )
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _c("span", { staticClass: "ml_auto cal_btn" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchParams.reqBizCatCd,
                    expression: "searchParams.reqBizCatCd",
                  },
                ],
                staticClass: "wid120",
                attrs: { id: "req" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchParams,
                        "reqBizCatCd",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    function ($event) {
                      return _vm.checkSearchValidation("req")
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.013"))),
                ]),
                _vm._l(_vm.searchReqList, function (cvo, idx) {
                  return [
                    cvo.showFlag === "Y"
                      ? _c(
                          "option",
                          { key: "req" + idx, domProps: { value: cvo.cd } },
                          [_vm._v(_vm._s(cvo.cdNm))]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchParams.reqStatusCd,
                    expression: "searchParams.reqStatusCd",
                  },
                ],
                staticClass: "wid100 ml2",
                attrs: { id: "statusCd" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchParams,
                        "reqStatusCd",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    function ($event) {
                      return _vm.checkSearchValidation("pro")
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CMBA380.034"))),
                ]),
                _c("option", { attrs: { value: "01" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CMBA380.035"))),
                ]),
                _c("option", { attrs: { value: "02" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CMBA380.036"))),
                ]),
                _c(
                  "option",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchParams.reqBizCatCd == "WA01",
                        expression: "searchParams.reqBizCatCd == 'WA01'",
                      },
                    ],
                    attrs: { value: "05" },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.CMBA380.037")))]
                ),
                _c("option", { attrs: { value: "03" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CMBA380.038"))),
                ]),
                _c("option", { attrs: { value: "04" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CMBA380.039"))),
                ]),
              ]
            ),
            _c(
              "a",
              {
                staticClass: "button sm ml2",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.exlDownload()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.MYIN020T010.016")))]
            ),
          ]),
        ]),
        _c("div", {
          style: `width: 100%; height: ${_vm.gridHeight}px`,
          attrs: { id: "realgrid" },
        }),
      ]),
      _c(
        "win-layer-pop",
        { staticClass: "my_request_doc" },
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popupParams },
                on: { close: _vm.closePopup },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "260px" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "100px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }