<template>
  <!-- 나의 요청사항 -->
  <div>
    <div class="content_box beforetab" id="working-progress-box">
      <form id="searchForm" @submit.prevent>
        <table class="tbl_search">
          <colgroup>
            <col width="150px">
            <col width="260px">
            <col width="*">
            <col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <td class="pr5">
                <select v-model="searchParams.kind" id="kind" @change="checkSearchValidation('kind')">
                  <option value="">{{ $t('msg.ONEX010T010.004') }}<!-- 선택 --></option>
                  <option v-for="(cvo, idx) in searchKindList" :key="'kind' + idx" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td class="text_left pl0">
                <div v-if="searchParams.kind == 'BK' || searchParams.kind == 'BL'">
                  <input
                    type="text" id="keyword"
                    v-model="searchParams.keyword"
                    :placeholder="placeHolder"
                    @keyup="checkSearchValidation('keyword')"
                    @keypress.enter="search()"
                    @input="searchParams.keyword=searchParams.keyword.toUpperCase()"
                  >
                </div>
                <div v-else class="form_wrap t2">
                  <e-date-range-picker-simple
                    id="fromDtToDt"
                    :sdate="searchParams.fromDt"
                    :edate="searchParams.toDt"
                    @change="changeDateRange"
                  />
                </div>
              </td>
              <td>
                <span>
                  <input type="radio" id="profileYn_Y" name="profileYn" v-model="searchParams.profileYn" value="Y">
                  <label for="profileYn_Y" class="mr10"><span></span>{{ $t('msg.ONEX010T010.006') }}<!-- My Shipments --></label>
                  <span class="position_relative mr20">
                    <button type="button" class="tbl_icon help" @click="showHelp01 = !showHelp01">help</button>
                    <div v-show="showHelp01" class="tooltip_wrap short position_absolute" style="width:180px; left:0; top:24px;">
                      <div class="cont">
                        <button type="button" class="close" @click="showHelp01 = false">close</button>
                        <ul class="bul_list_sm t2">
                          <li v-html="$t('msg.ONEX010T010.007')"></li>
                        </ul>
                      </div>
                    </div>
                  </span>
                  <input type="radio" id="profileYn_N" name="profileYn" v-model="searchParams.profileYn" value="N">
                  <label for="profileYn_N" class="mr10"><span></span>{{ $t('msg.ONEX010T010.008') }}<!-- All Shipments --></label>
                  <span class="position_relative mr20">
                    <button type="button" class="tbl_icon help" @click="showHelp02 = !showHelp02">help</button>
                    <div v-show="showHelp02" class="tooltip_wrap short position_absolute" style="width:200px; left:0; top:24px;">
                      <div class="cont">
                        <button type="button" class="close" @click="showHelp02 = false">close</button>
                        <ul class="bul_list_sm t2">
                          <li v-html="$t('msg.ONEX010T010.009')"></li>
                        </ul>
                      </div>
                    </div>
                  </span>
                </span>
              </td>
              <td class="text_right">
                <a class="button blue sh" href="#" @click.prevent="search()">{{ $t('msg.ONEX010T010.010') }}<!-- 검색 --></a>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
    <div class="content_box mt10">
      <div class="flex_box">
        <span class="tot_num">{{ $t('msg.ONEX010T010.011') }}<!-- Total --> : <span class="num">{{ totalCount }}</span></span>
        <span v-if="relYn" class="input_box">
          <select class="wid100" v-model="selPo">
            <option value="POL">Departure</option>
            <option value="POD">Arrival</option>
          </select>
        </span>
        <span v-if="relYn" class="input_box">
          <select class="wid200" v-model="selCtr" @change="checkSelectCtr()">
            <option value="">{{ $t('msg.ONEX010T010.004') }}<!-- 선택 --></option>
            <option v-for="ctrInfo in ctrCdList" :key="ctrInfo.ctrCd" :value="ctrInfo.ctrCd">{{ ctrInfo.ctrEnm }}</option>
          </select>
        </span>
        <span class="ml_auto cal_btn">
          <select class="wid120" v-model="searchParams.reqBizCatCd" id="req" @change="checkSearchValidation('req')">
            <option value="">{{ $t('msg.ONEX010T010.013') }}</option><!-- 요청사항 -->
            <template v-for="(cvo, idx) in searchReqList">
              <option v-if="cvo.showFlag === 'Y'" :key="'req' + idx" :value="cvo.cd">{{ cvo.cdNm }}</option>
            </template>
          </select>
          <select class="wid100 ml2" v-model="searchParams.reqStatusCd" id="statusCd" @change="checkSearchValidation('pro')">
            <option value="">{{ $t('msg.CMBA380.034') }}<!-- 선택 --></option>
            <option value="01">{{ $t('msg.CMBA380.035') }}</option>
            <option value="02">{{ $t('msg.CMBA380.036') }}</option>
            <option v-show="searchParams.reqBizCatCd == 'WA01'" value="05">{{ $t('msg.CMBA380.037') }}</option>
            <option value="03">{{ $t('msg.CMBA380.038') }}</option>
            <option value="04">{{ $t('msg.CMBA380.039') }}</option>
          </select>
          <a class="button sm ml2" href="#" @click.prevent="exlDownload()">{{ $t('msg.MYIN020T010.016') }}</a> <!-- 엑셀 다운로드 -->
        </span>
      </div>
      <div id="realgrid" :style="`width: 100%; height: ${gridHeight}px`" />
      <!-- paging -->
      <!-- <div
        v-if="list.length > 0"
        class="paging"
      >
        <a
          class="btn start"
          @click.prevent="getPaging(1, false)"
        >Start</a>
        <a
          class="btn prev"
          @click.prevent="getPaging(pagination.prevPage, false)"
        >Prev</a>
        <a
          v-for="idx in pageNumberRange"
          :key="idx"
          :class="[idx === searchParams.curPage ? 'on' : '']"
          href="#"
          @click.prevent="getPaging(idx, false)"
        >{{ idx }}</a>
        <a
          class="btn next"
          @click.prevent="getPaging(pagination.nextPage, false)"
        >Next</a>
        <a
          class="btn end"
          @click.prevent="getPaging(pagination.pageCnt, false)"
        >End</a>
      </div> -->
      <!-- paging // -->
    </div>
    <win-layer-pop class="my_request_doc">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closePopup"
        :parent-info="popupParams"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import commons from '@/api/services/commons'
import docs from '@/api/rest/trans/docs'
import { rootComputed } from '@/store/helpers'
import { GridView, LocalDataProvider } from 'realgrid'
import myRequest from '@/api/rest/trans/myRequest'
import transCommon from '@/api/rest/trans/transCommon'
import containerReturn from '@/api/rest/trans/containerReturn'
import JSZip from 'jszip'
import moment from 'moment'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'

let gridView = GridView
let provider = LocalDataProvider

const fields = [
  { fieldName: 'reqDate', dataType: 'datetime', datetimeFormat: 'yyyyMMddhhmm' },
  { fieldName: 'reqBizCatCd', dataType: 'text' },
  { fieldName: 'reqBizCatNm', dataType: 'text' },
  { fieldName: 'reqPicNm', dataType: 'text' },
  { fieldName: 'refRno', dataType: 'text' },
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'bkgNo', dataType: 'text' },
  { fieldName: 'porPlcCd', dataType: 'text' },
  { fieldName: 'porPlcNm', dataType: 'text' },
  { fieldName: 'porCtrCd', dataType: 'text' },
  { fieldName: 'dlyPlcNm', dataType: 'text' },
  { fieldName: 'dlyCtrCd', dataType: 'text' },
  { fieldName: 'reqNm', dataType: 'text' },
  { fieldName: 'apvStsCd', dataType: 'text' },
  { fieldName: 'apvStsNm', dataType: 'text' },
  { fieldName: 'apvStsEnm', dataType: 'text' },
  { fieldName: 'apvDate', dataType: 'text' },
  { fieldName: 'apvUno', dataType: 'text' },
  { fieldName: 'apvNm', dataType: 'text' },
  { fieldName: 'reqUno', dataType: 'text' },
  { fieldName: 'rejectCont', dataType: 'text' },
  { fieldName: 'bottomKey', dataType: 'text' },
  { fieldName: 'apvCancel', dataType: 'text' },
  { fieldName: 'ntcCd', dataType: 'text' }
]

const columns = [
  {
    name: 'reqDate',
    fieldName: 'reqDate',
    header: { text: app.$t('msg.CMBA380.005') },
    width: '80',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const reqDate = grid.getValue(idx, 'reqDate')
        if (reqDate) {
          let year = reqDate.getFullYear()
          let month = reqDate.getMonth() + 1
          let date = reqDate.getDate()
          let hour = reqDate.getHours()
          let time = reqDate.getMinutes()
          if (month < 10) month = '0' + month
          if (date < 10) date = '0' + date
          if (hour < 10) hour = '0' + hour
          if (time < 10) time = '0' + time
          return `<span class="font_11">${year}.${month}.${date} ${hour}:${time}</span>`
        }
      }
    }
  },
  {
    name: 'reqBizCatNm',
    fieldName: 'reqBizCatNm',
    header: { text: app.$t('msg.ONEX010T010.013') },
    width: '100',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const reqBizCatCd = grid.getValue(idx, 'reqBizCatCd') || ''
        const bkgNo = grid.getValue(idx, 'bkgNo') || ''
        const blNo = grid.getValue(idx, 'blNo') || ''
        const refRno = grid.getValue(idx, 'refRno') || ''
        const porCtrCd = grid.getValue(idx, 'porCtrCd') || ''
        const dlyCtrCd = grid.getValue(idx, 'dlyCtrCd') || ''
        const bizName = app.$t('msg.CMBA380.' + reqBizCatCd)
        const bizChkCd = ['DA01', 'DA09', 'DA10', 'DA17', 'DA99', 'WA01', 'RE08']
        if (bizChkCd.includes(reqBizCatCd)) {
          return bizName
        } else {
          if (vmApp.auth.userCtrCd !== 'KR' && reqBizCatCd === 'DA07') {
            return bizName
          } else {
            return `<a href="#" class="link" onclick="vmApp.fnLink(event, '${reqBizCatCd}','${bkgNo}', '${blNo}', '${refRno}', '${porCtrCd}', '${dlyCtrCd}')">${bizName}</a>`
          }
        }
      }
    }
  },
  {
    name: 'blNo',
    fieldName: 'blNo',
    header: { text: app.$t('msg.ONEX010T010.145') }, // BKG/ B.L No.
    width: '80',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const reqBizCatCd = grid.getValue(idx, 'reqBizCatCd') || ''
        const bkgNo = grid.getValue(idx, 'bkgNo') || ''
        const blNo = grid.getValue(idx, 'blNo') || ''
        const html = []
        let listKey = blNo
        if (reqBizCatCd === 'RE05' || reqBizCatCd === 'RE16' || reqBizCatCd === 'WA02') {
          listKey = bkgNo
        }
        return listKey
      }
    }
  },
  {
    name: 'porPlcNm',
    fieldName: 'porPlcNm',
    header: { text: app.$t('msg.CMBA380.024'), styleName: 'multi-line-css' },
    width: '100',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const porPlcNm = grid.getValue(idx, 'porPlcNm') || ''
        const porCtrCd = grid.getValue(idx, 'porCtrCd') || ''
        const html = []
        html.push(porPlcNm)
        html.push(porCtrCd)
        return html.join(', ')
      }
    }
  },
  {
    name: 'dlyPlcNm',
    fieldName: 'dlyPlcNm',
    header: { text: app.$t('msg.CMBA380.025'), styleName: 'multi-line-css' },
    width: '100',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const dlyPlcNm = grid.getValue(idx, 'dlyPlcNm') || ''
        const dlyCtrCd = grid.getValue(idx, 'dlyCtrCd') || ''
        const html = []
        html.push(dlyPlcNm)
        html.push(dlyCtrCd)
        return html.join(', ')
      }
    }
  },
  { name: 'reqPicNm', fieldName: 'reqPicNm', header: { text: app.$t('msg.ONEX010T010.146') }, width: '80', editable: false, sortable: false },
  {
    name: 'apvStsNm',
    fieldName: 'apvStsNm',
    header: { text: app.$t('msg.ONEX010T010.147') },
    width: '80',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const reqBizCatCd = grid.getValue(idx, 'reqBizCatCd') || ''
        const apvStsNm = grid.getValue(idx, 'apvStsNm') || ''
        const apvStsCd = grid.getValue(idx, 'apvStsCd') || ''
        const apvStsEnm = grid.getValue(idx, 'apvStsEnm') || ''
        const rejectCont = grid.getValue(idx, 'rejectCont') || ''
        const bottomKey = grid.getValue(idx, 'bottomKey') || ''
        const html = []
        let apvNm = (vmApp.auth.serviceLang === 'KOR' ? apvStsNm : apvStsEnm)

        //인보이스 등록[DA01], 세금계산서 발행[DA14], 운임 연장[RA01], 운임 네고[RA02], 운임 문의[RA03], 운임 정정[RE12], 마일리지 전환[MI01]
        //FREETIME[FR01], 반납지 변경[], 부킹 캔슬 제외
        let arrRaChk = ['DA01', 'RA01', 'RA02', 'RA03']
        const arrRejectChk = [...arrRaChk, 'MI01', 'FR01']
        if (!arrRejectChk.includes(reqBizCatCd) && rejectCont !== '') {
          html.push('<span class="span_tooltip">')
          html.push(' <span class="label2 ' + vmApp.colorMap.get(apvStsNm) + '" style="text-decoration: underline;">')
          html.push('   <a onmouseover="vmApp.showTooltip(event)" onmouseout="vmApp.hideTooltip(event)">' + apvNm + '</a>')
          html.push(' </span>')
          if (reqBizCatCd === 'RE12' && apvStsNm === '거절') {
            html.push('<br/>' + rejectCont)
          }
          html.push(' <div onmouseout="vmApp.hideTooltip(event)" class="tooltip_wrap short position_absolute" style="width:250px;white-space: normal;display:none;left:65px;top:24px;">')
          html.push('   <div class="cont">')
          html.push('     <ul class="bul_list_sm t2">')
          html.push('       <li>')
          html.push(rejectCont)
          html.push('       </li>')
          html.push('     </ul>')
          html.push('   </div>')
          html.push(' </div>')
          html.push('</span>')
          return html.join('')
        } else {
          if (vmApp.auth.serviceLang !== 'KOR' && apvStsCd === '08' && reqBizCatCd.indexOf('CA') > -1) {
            apvNm = 'Pending'
          }

          html.push('<span class="label2 ' + vmApp.colorMap.get(apvStsNm) + '">')
          html.push(apvNm)
          html.push('</span>')
          arrRaChk = [...arrRaChk, 'DA14', 'RE12']
          if (arrRaChk.includes(reqBizCatCd) && apvStsNm === '승인') {
            html.push('<br/>' + bottomKey)
          }
          return html.join('\n')
        }
      }
    }
  },
  {
    name: 'apvDate',
    fieldName: 'apvDate',
    header: { template: '<div>' + app.$t('msg.ONEX010T010.148') + '</div><div style="color: #777777;">' + app.$t('msg.ONEX010T010.149') + '</div>' },
    width: '80',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const reqBizCatCd = grid.getValue(idx, 'reqBizCatCd') || ''
        let chgDate = ''
        let apvStsNm = grid.getValue(idx, 'apvStsNm') || ''
        apvStsNm = apvStsNm.replace(/\s/gi, '')
        if ((apvStsNm === '승인' || apvStsNm === '거절' || apvStsNm === '취소' || apvStsNm === '터미널요청완료')) {
          const apvDate = grid.getValue(idx, 'apvDate') || ''
          chgDate = vmApp.changeDatePattern(apvDate)
        } else {
          let reqDate = grid.getValue(idx, 'reqDate') || ''
          if (reqDate !== '') {
            let year = reqDate.getFullYear()
            let month = reqDate.getMonth() + 1
            let date = reqDate.getDate()
            let hour = reqDate.getHours()
            let time = reqDate.getMinutes()
            if (month < 10) month = '0' + month
            if (date < 10) date = '0' + date
            if (hour < 10) hour = '0' + hour
            if (time < 10) time = '0' + time
            reqDate = year + '' + month + '' + date + '' + hour + '' + time
            vmApp.timeList.some((obj, idx) => {
              if (reqBizCatCd.indexOf(obj.cd) > -1 && reqBizCatCd.indexOf('WA01') < 0 && obj.dsc) {
                const reqStrDt = vmApp.$ekmtcCommon.getStrToDate(reqDate)
                reqStrDt.setMinutes(reqStrDt.getMinutes() + Number(obj.dsc))

                let chgHour = reqStrDt.getHours()
                let chgMinutes = reqStrDt.getMinutes()

                chgHour = vmApp.getChgNum(chgHour)
                chgMinutes = vmApp.getChgNum(chgMinutes)

                if (vmApp.auth.serviceLang === 'KOR') {
                  chgDate = '<span class="color_gray">' + chgHour + '시 ' + chgMinutes + '분</span>'
                } else {
                  chgDate = '<span class="color_gray">' + chgHour + ': ' + chgMinutes + '</span>'
                }
                return true
              }
            })
          }
        }
        return `${chgDate}`
      }
    }
  },
  {
    name: 'apvNm',
    fieldName: 'apvNm',
    header: { text: app.$t('msg.CSBK100.284') },
    width: '80',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const reqBizCatCd = grid.getValue(idx, 'reqBizCatCd') || ''
        const apvUno = grid.getValue(idx, 'apvUno') || ''
        const porPlcCd = grid.getValue(idx, 'porPlcCd') || ''
        const btnNm = app.$t('msg.ONIM070P020.006')
        const apvStsNm = grid.getValue(idx, 'apvStsNm') || ''
        const refRno = grid.getValue(idx, 'refRno') || ''
        const ntcCd = grid.getValue(idx, 'ntcCd') || ''
        let blNo = grid.getValue(idx, 'blNo') || ''

        if (reqBizCatCd === 'RE05' || reqBizCatCd === 'RE16' || reqBizCatCd === 'WA02' || reqBizCatCd === 'RE06') {
          blNo = grid.getValue(idx, 'bkgNo') || ''
        }

        return `<button class="tbl_icon tel" onclick="vmApp.fnUserPop(event, '${reqBizCatCd}', '${apvUno}', '${apvStsNm}', '${porPlcCd}', '${blNo}', '${refRno}', '${ntcCd}')">${btnNm}</button>`
      }
    }
  },
  {
    name: 'apvCancel',
    fieldName: 'apvCancel',
    header: { text: app.$t('msg.ONIM070G010.036') },
    width: '80',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const reqBizCatCd = grid.getValue(idx, 'reqBizCatCd') || ''
        const apvStsNm = grid.getValue(idx, 'apvStsNm') || ''
        const refRno = grid.getValue(idx, 'refRno') || ''

        if (reqBizCatCd === 'WA01' || reqBizCatCd === 'WA02' || reqBizCatCd === 'MI01' || reqBizCatCd === 'DA07' || reqBizCatCd === 'CA01') {
          return ''
        } else {
          if (apvStsNm === '처리중') {
            const btnNm = app.$t('msg.CMBA380.039')
            return `<a href="#"class="button sm" onclick="vmApp.fnCancel(event, '${reqBizCatCd}', '${refRno}')">${btnNm}</a>`
          } else {
            return ''
          }
        }
      }
    }
  }
]

const layer = [
  'reqDate',
  'reqBizCatNm',
  'blNo',
  'porPlcNm',
  'dlyPlcNm',
  'reqPicNm',
  'apvStsNm',
  'apvDate',
  'apvNm'
  //'apvCancel'
]

export default {
  name: 'WorkingMyRequest',
  components: {
    WinLayerPop,
    MyRequestDocPop: () => import('@/pages/trans/popup/MyRequestDocPop'),
    MyRequestUserPop: () => import('@/pages/trans/popup/MyRequestUserPop'),
    EDateRangePickerSimple: () => import('@/components/common/EDateRangePickerSimple'),
    ShippingLogBLDetailPop: () => import('@/pages/trans/popup/ShippingLogBLDetailPop'), /* 선적로그 상세(C/A DETAIL) 팝업 */
    BookingCancelFeePop: () => import('@/pages/trans/popup/BookingCancelFeePop')
  },
  data () {
    return {
      showHelp01: false,
      showHelp02: false,
      totalCount: 0,
      gridHeight: 300,
      customComponent: null,
      //pagination: {},
      list: {},
      linkParams: {
        reqBizCatCd: '',
        blNo: '',
        bkgNo: '',
        refRno: '',
        porCtrCd: '',
        dlyCtrCd: ''
      },
      colorMap: new Map([
        ['거절', 'red'],
        ['취소', 'red'],
        ['승인', 'green'],
        ['처리중', 'gray'],
        ['터미널 요청 완료', 'gray']
      ]),
      popupParams: {
        reqBizCatCd: '',
        reqRno: ''
      },
      searchKindList: [
        { cd: 'RD', cdNm: this.$t('msg.CMBA380.005') },
        { cd: 'AD', cdNm: this.$t('msg.CMBA380.006') },
        { cd: 'BK', cdNm: 'BKG & B/L No.' }
      ],
      searchReqList: [],
      timeList: [],
      searchParams: {
        kind: 'RD',
        reqBizCatCd: '',
        reqStatusCd: '',
        fromDt: '',
        toDt: '',
        keyword: '',
        profileYn: 'Y'
        //curPage: 1, // 현재 페이지
        //pageSize: 10 // 한 페이지당 게시글 수
      },
      afterClickImport: 'N',
      placeHolder: 'BKG/ B.L No.',
      ctrCdList: [],
      selCtr: '',
      selPo: 'POL',
      relYn: true //process.env.VUE_APP_MODE !== 'PRD' // JamSin
    }
  },
  computed: {
    ...rootComputed
    // pageNumberRange: function () {
    //     return [...Array(this.pagination.pageNumberSize).keys()].map(i => i + this.pagination.startPage)
    // }
  },
  created () {
    this.gridHeight = $('body').height() - 400
    if (this.gridHeight < 300) {
      this.gridHeight = 300
    }

    const ctrCd = this.memberDetail.userCtrCd
    // D/O issue은 해외인경우 일본(JP), 인도네시아(ID)만 허용
    // 5월06일 추가 --09 : DEM, 10 : DET, 17 : OFC, 99 : DFC. DEM/DET/OFC/DFC는 국내만 허용
    // 2022.08.31 정렬기준 코드명으로 변경
    this.searchReqList = []
    this.searchReqList.push({ type: 'RE', cd: 'RE06,RE22', cdNm: this.$t('msg.CMBA380.RE06'), showFlag: 'Y' })
    this.searchReqList.push({ type: 'CE', cd: 'CE01', cdNm: this.$t('msg.CMBA380.CE01'), showFlag: 'Y' })
    this.searchReqList.push({ type: 'DA', cd: 'DA02,DA03,DA06', cdNm: this.$t('msg.CMBA380.DA02'), showFlag: 'Y' })
    this.searchReqList.push({ type: 'CA', cd: 'CA01', cdNm: this.$t('msg.CMBA380.CA01'), showFlag: 'Y' })
    this.searchReqList.push({ type: 'RE', cd: 'RE15', cdNm: this.$t('msg.CMBA380.RE15'), showFlag: 'Y' })
    this.searchReqList.push({ type: 'DA', cd: 'DA07', cdNm: this.$t('msg.CMBA380.DA07'), showFlag: (['KR', 'ID', 'JP'].indexOf(ctrCd) >= 0 ? 'Y' : 'N') }) // D/O issue
    this.searchReqList.push({ type: 'FR', cd: 'FR01', cdNm: this.$t('msg.CMBA380.FR01'), showFlag: 'Y' })
    this.searchReqList.push({ type: 'RE', cd: 'RE08', cdNm: this.$t('msg.CMBA380.RE08'), showFlag: 'Y' })
    this.searchReqList.push({ type: 'RE', cd: 'RE14', cdNm: this.$t('msg.CMBA380.RE14'), showFlag: 'Y' })
    this.searchReqList.push({ type: 'WA', cd: 'WA01', cdNm: this.$t('msg.CMBA380.WA01'), showFlag: this.ctrChek() })
    // this.searchReqList.push({ type: 'MI', cd: 'MI01', cdNm: this.$t('msg.CMBA380.MI01') })
    this.searchReqList.push({ type: 'DA', cd: 'DA05', cdNm: this.$t('msg.CMBA380.DA05'), showFlag: this.ctrChek() })
    this.searchReqList.push({ type: 'WA', cd: 'WA02', cdNm: this.$t('msg.CMBA380.WA02'), showFlag: this.ctrChek() })
    this.searchReqList.push({ type: 'RE', cd: 'RE01', cdNm: this.$t('msg.CMBA380.RE01'), showFlag: this.ctrChek() })
    this.searchReqList.push({ type: 'DA', cd: 'DA14', cdNm: this.$t('msg.CMBA380.DA14'), showFlag: this.ctrChek() })
    this.searchReqList.push({ type: 'DA', cd: 'DA09', cdNm: this.$t('msg.CMBA380.DA09'), showFlag: this.ctrChek() })
    this.searchReqList.push({ type: 'DA', cd: 'DA10', cdNm: this.$t('msg.CMBA380.DA10'), showFlag: this.ctrChek() })
    this.searchReqList.push({ type: 'DA', cd: 'DA99', cdNm: this.$t('msg.CMBA380.DA99'), showFlag: this.ctrChek() })
    this.searchReqList.push({ type: 'DA', cd: 'DA17', cdNm: this.$t('msg.CMBA380.DA17'), showFlag: this.ctrChek() })
    this.searchReqList.push({ type: 'RA', cd: 'RA02', cdNm: this.$t('msg.CMBA380.RA02'), showFlag: 'Y' })
    this.searchReqList.push({ type: 'RA', cd: 'RA03', cdNm: this.$t('msg.CMBA380.RA03'), showFlag: 'Y' })
    this.searchReqList.push({ type: 'RA', cd: 'RA01', cdNm: this.$t('msg.CMBA380.RA01'), showFlag: 'Y' })
    this.searchReqList.push({ type: 'RE', cd: 'RE12', cdNm: this.$t('msg.CMBA380.RE12'), showFlag: 'Y' })
    this.searchReqList.push({ type: 'RE', cd: 'RE05', cdNm: this.$t('msg.CMBA380.RE05'), showFlag: this.ctrChek() })
    this.searchReqList.push({ type: 'DA', cd: 'DA01', cdNm: this.$t('msg.CMBA380.DA01'), showFlag: 'Y' })
    this.searchReqList.push({ type: 'RE', cd: 'RE16', cdNm: this.$t('msg.CMBA380.RE16'), showFlag: this.ctrChek() })
    this.searchReqList.push({ type: 'RE', cd: 'RE17', cdNm: this.$t('msg.CMBA380.RE17'), showFlag: this.ctrChek() })
    this.searchReqList.push({ type: 'RE', cd: 'RE02', cdNm: this.$t('msg.CMBA380.RE02'), showFlag: this.ctrChek() })
    this.searchReqList.push({ type: 'RE', cd: 'RE23', cdNm: this.$t('msg.CMBA380.RE23'), showFlag: 'Y' })
  },
  mounted () {
    window.vmApp = this
    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip

    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    // 그리드 필드 생성
    provider.setFields(fields)
    gridView.setColumnLayout(layer)
    gridView.setColumns(columns)

    gridView.setRowIndicator({ visible: false })
    gridView.setStateBar({ visible: false })
    gridView.setCheckBar({ visible: false })
    gridView.setFooter({ visible: false })
    gridView.setCopyOptions({ copyDisplayText: true, lookupDisplay: true, copyTextCallback: this.copyTextCallback })

    gridView.header.height = 80
    gridView.displayOptions.rowHeight = 60
    gridView.displayOptions.fitStyle = 'evenFill'

    this.gridEvents()
    this.init()
  },
  methods: {
    async init () {
      this.initCommCodes()
      const arrMonthInfo = this.$ekmtcCommon.getDefaultStEnDt({ startCode: 'W', startGap: 1, endCode: '', endGap: 0 })
      const def = {
        profileYn: 'N',
        kind: 'RD',
        fromDt: arrMonthInfo.stDt,
        toDt: arrMonthInfo.enDt
      }

      this.searchParams = { ...this.searchParams, ...def }
      //JamSin 속도 문제로 막음
      //this.search()
      if (this.$route.params.initBookingNo) {
        this.searchParams.kind = 'BK'
        this.searchParams.keyword = this.$route.params.initBookingNo
        this.$nextTick(() => {
          this.search()
        })
      }
    },
    checkSelectCtr () {
      if (this.selCtr !== '') {
        if (this.selPo === 'POL') {
          console.log('POL' + this.selCtr)
          this.gridList = this.list.filter(vo => vo.porCtrCd === this.selCtr)
        } else if (this.selPo === 'POD') {
          console.log('POD' + this.selCtr)
          this.gridList = this.list.filter(vo => vo.dlyCtrCd === this.selCtr)
        }
        this.totalCount = this.gridList.length
        provider.setRows(this.gridList)
      } else {
        this.gridList = this.list
        this.totalCount = this.gridList.length
        provider.setRows(this.gridList)
      }
    },
    async initCommCodes () {
      await commons.getCommonsCodesCountry().then(res => {
        this.ctrCdList = res
      })
      const arrCdId = ['WB017']
      // 공통코드 가져오기
      await commons.getCommonsCodesCommon(arrCdId).then(res => {
        this.commonCodes = res
        this.timeList = this.commonCodes.WB017
      })
    },
    ctrChek () {
      const ctrCd = this.memberDetail.userCtrCd
      if (ctrCd === 'KR') {
        return 'Y'
      } else {
        return 'N'
      }
    },
    search () {
      let isOk = true
      const prefix = ['kind', 'fromDtToDt', 'keyword']
      prefix.forEach(el => {
        if (!this.checkSearchValidation(el)) {
          isOk = false
        }
      })

      if (!isOk) {
        return
      }

      //this.searchParams.curPage = 1
      if (this.searchParams.fromDt === '' && this.searchParams.toDt === '') {
        const arrMonthInfo = this.$ekmtcCommon.getDefaultStEnDt({ startCode: 'W', startGap: 1, endCode: 'W', endGap: 1 })
        if (this.afterClickImport === 'N') {
          this.searchParams.fromDt = arrMonthInfo.stDt
          this.searchParams.toDt = arrMonthInfo.enDt
          this.afterClickImport = 'Y'
        }
      }
      this.getMyRequestList()
    },
    setGridData (list) {
      this.list = list
      provider.setRows(list)
    },
    // async getPaging (index, searchFlag) {
    //   if (!this.auth.userId) {
    //     await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
    //     return
    //   }

    //   const alertOpt = {
    //     alertType: 'simple',
    //     customCloseBtnText: this.$t('msg.CMBA100.546'), // 닫기
    //     useConfirmBtn: false
    //   }

    //   if (index < 1) {
    //     this.$ekmtcCommon.alert({
    //       ...alertOpt, message: this.$t('js.common.002') // 처음페이지입니다.
    //     })
    //     return
    //   } else if (this.pagination && index > this.pagination.pageCnt) {
    //     this.$ekmtcCommon.alert({
    //       ...alertOpt, message: this.$t('js.common.001') // 마지막페이지입니다.
    //     })
    //     return
    //   }
    //   if (!searchFlag && this.searchParams.curPage === index) return
    //   this.searchParams.curPage = index
    //   this.getMyRequestList()
    // },
    getMyRequestList () {
      const TH = this

      const tempPrevData = sessionStorage.getItem('working-my-request-prev-data')
      const prevData = tempPrevData === null || tempPrevData === undefined ? undefined : JSON.parse(tempPrevData)
      if (prevData) {
        if (prevData.checkedRows.length > 0) {
          gridView.checkItems(prevData.checkedRows, true)
          this.setRequestArea(gridView.getCheckedRows())
        }

        if (prevData.gridSelect !== undefined) {
          gridView.setCurrent(prevData.gridSelect)
        }
        this.searchParams = prevData.searchParams
        sessionStorage.removeItem('working-my-request-prev-data')
      }
      myRequest.getMyRequestList(this.searchParams).then(async res => {
        const list = res.data.myRequestList
        if (list) {
//          const pagination = res.data.pagination
          TH.setGridData(list)
          TH.totalCount = list.length
          // TH.pagination = pagination
          const logParams = {
            arrReqDtm: [],
            arrReqBizCatCd: []
          }
          list.forEach(obj => {
            logParams.arrReqDtm.push(obj.reqDate)
            logParams.arrReqBizCatCd.push(obj.reqBizCatCd)
          })
          try {
            myRequest.postMyRequestLog(logParams).then(subRes => {})
          } catch (error) {
            console.log(error)
          }
        } else {
          TH.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
          TH.setGridData({})
          TH.totalCount = 0
          // TH.pagination = {}
        }
      })
    },
    getDelegateYn (type, code) {
      // CD_ID = 01007 (30: 수출 ALL, 39: 컨테이너 셔틀 요청)
      let delegateYn = false
      if (type === 'BL') {
        transCommon.getBlDelegateYn({ blNo: this.linkParams.blNo, arrCstGrpCatCd: ['30', code] }).then(async (res) => {
          if (res.data === 'Y') {
            delegateYn = true
          }
        })
      } else {
        transCommon.getBkgDelegateYn({ bkgNo: this.linkParams.bkgNo, arrCstGrpCatCd: ['30', code] }).then(async (res) => {
          if (res.data === 'Y') {
            delegateYn = true
          }
        })
      }
      return delegateYn
    },
    checkSearchValidation (type) {
      const frm = document.querySelector('#searchForm')
      const selector = frm.querySelector('#' + type)
      this.$ekmtcCommon.hideErrorTooltip(selector)
      if (type === 'req') {
        const arrReq = ['DA', 'RE', 'CE', 'MI', 'CA', 'FR', 'RA', 'WA']
        this.searchParams.reqBizCatType = ''
        arrReq.some((obj, idx) => {
          if (this.searchParams.reqBizCatCd.indexOf(obj) > -1) {
            this.searchParams.reqBizCatType = obj
            return true
          }
        })
        this.search()
      } else if (type === 'pro') {
        this.search()
      } else if (type === 'fromDtToDt') {
        if (this.searchParams.kind !== 'BK' && (this.$ekmtcCommon.isEmpty(this.searchParams.fromDt) || this.$ekmtcCommon.isEmpty(this.searchParams.toDt))) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          return false
        } else if (this.searchParams.kind !== 'BK' && parseInt(this.searchParams.fromDt, 10) > parseInt(this.searchParams.toDt, 10)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.ONEX010T010.128'))
          return false
        }
      } else if (type === 'kind') {
        if (this.searchParams.kind === '') {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          return false
        }
      } else if (type === 'keyword') {
        if (this.searchParams.kind === 'BK' && this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          return false
        }
      }
      return true
    },
    changeDateRange (s, e) {
      this.searchParams.fromDt = s
      this.searchParams.toDt = e
      this.checkSearchValidation('fromDtToDt')
    },
    changeDatePattern (strDate, char) {
      let result = ''
      if (this.$ekmtcCommon.isEmpty(strDate)) {
        return ''
      }

      if (this.$ekmtcCommon.isEmpty(char)) {
        char = '.'
      }

      if (char !== 'KO') {
        if (strDate !== null && strDate.length >= 12) {
          result += strDate.substring(0, 4) + char + strDate.substring(4, 6) + char + strDate.substring(6, 8) + ' ' + strDate.substring(8, 10) + ':' + strDate.substring(10, 12)
        } else if (strDate !== null && strDate.length >= 8) {
          result += strDate.substring(0, 4) + char + strDate.substring(4, 6) + char + strDate.substring(6, 8)
        } else if (strDate !== null && strDate.length() >= 6) {
          result += strDate.substring(0, 4) + char + strDate.substring(4, 6)
        }
      } else {
        if (strDate !== null && strDate.length >= 12) {
          result += strDate.substring(0, 4) + '년' + strDate.substring(4, 6) + '월' + strDate.substring(6, 8) + '일' + ' ' + strDate.substring(8, 10) + '시' + strDate.substring(10, 12) + '분'
          result += ' ' + strDate.substring(8, 10) + ':' + strDate.substring(10, 12)
        } else if (strDate !== null && strDate.length >= 8) {
          result += strDate.substring(0, 4) + '년' + strDate.substring(4, 6) + '월' + strDate.substring(6, 8) + '일'
        } else if (strDate !== null && strDate.length() >= 6) {
          result += strDate.substring(0, 4) + '년' + strDate.substring(4, 6) + '월'
        }
      }

      return result
    },
    getChgNum (num) {
      if (num < 10) num = '0' + num
      return num
    },
    gridEvents () {
      /* 아래 소스 실행시 Grid에서 Ctrl C 안먹게 되므로 실행하지 말것
      const TH = this
      gridView.onCopy = (grid, range, event) => {
        const idx = range.startRow
        const reqBizCatCd = grid.getValue(idx, 'reqBizCatCd') || ''
        const bkgNo = grid.getValue(idx, 'bkgNo') || ''
        const blNo = grid.getValue(idx, 'blNo') || ''
        let copyKey = blNo
        const bookingaArr = ['RE05', 'RE16', 'WA02']
        if (bookingaArr.includes(reqBizCatCd)) {
          copyKey = bkgNo
        }
        if (copyKey) {
          if (window.clipboardData) {
            window.clipboardData.setData('Text', copyKey)
          } else {
            event.clipboardData.setData('text/plain', copyKey)
          }
        }
        return false
      } */
    },
    setCurrentData (gridCurrent) {
      let checkedRows = gridView.getCheckedRows()
      let prevData

      if (gridCurrent === undefined) {
        gridCurrent = gridView.getCurrent()
      }

      prevData = {
        searchParams: this.searchParams,
        gridSelect: {
          column: gridCurrent.column,
          dataRow: gridCurrent.dataRow,
          fieldIndex: gridCurrent.fieldIndex,
          fieldName: gridCurrent.fieldName,
          itemIndex: gridCurrent.itemIndex
        },
        checkedRows: checkedRows
      }

      sessionStorage.setItem('working-my-request-prev-data', JSON.stringify(prevData))
    },
    checkWebDataLink () {
      const cd = this.linkParams.reqBizCatCd
      if (cd === 'DA01') {
      //INVOICE 운임
      } else if (cd === 'DA02' || cd === 'DA03' || cd === 'DA06' || cd === 'RE08') {
        //B/L 발행, B/L 발행 - SURRENDER, B/L 발행 - WAYBILL
        myRequest.getDataSimple({ blNo: this.linkParams.blNo, reqRno: this.linkParams.refRno }).then(res => {
          const data = res.data.myRequest
          let blTypeNm = ''
          if (data.blTypCd === '02') {
            blTypeNm = 'SURRENDER B/L'
          } else if (data.blTypCd === '03') {
            blTypeNm = this.$t('msg.ONEX070G040.031')
          } else if (data.blTypCd === '06') {
            blTypeNm = 'ORIGINAL B/L'
          }
          const msgHtml = []
          msgHtml.push('<div style="text-align:left;">')
          msgHtml.push('- B/L Type : ' + blTypeNm)
          msgHtml.push('- Onboard Date : ' + this.changeDatePattern(data.obrdDt, '.'))
          msgHtml.push('</div>')
          this.$ekmtcCommon.alertDefault(msgHtml.join('\n'))
        })
      } else if (cd === 'DA04') {
      //ALLOCATION
      } else if (cd === 'DA05') {
      //반납지 변경
        const TH = this
        this.$ekmtcCommon.confirmCallBack(this.$t('msg.CMBA380.001'), () => {
          TH.fnLouter('container-return', 'Q', { searchType: 'BL', keyword: this.linkParams.blNo })
        })
      } else if (cd === 'DA07') {
      //D/O 발행
        myRequest.getIssDtm({ type: 'D', blNo: this.linkParams.blNo }).then(res => {
          const date = res.data ? this.changeDatePattern(res.data, '.') : '\n' + this.$t('msg.CMBA380.041')
          const param = {
                tab: '1',
                keyword: this.linkParams.blNo,
                dtKnd: 'BL',
                eiCatCd: 'I'
              }
          if (res.data) {
            // const msg = '정산/발행 화면으로 이동하시겠습니까? \n- ' + this.$t('msg.CMBA380.002') + ' : ' + date
            const msg = '정산/발행 화면으로 이동하시겠습니까?'

            this.$ekmtcCommon.confirmCallBack(msg, () => { this.$router.push({ path: '/calcIssue/calcIssueMain', query: param }) })
          } else {
            this.$ekmtcCommon.alertDefault('- ' + this.$t('msg.CMBA380.002') + ' : ' + date)
          }
        })
      } else if (cd === 'DA08') {
      //D/O 수신
      } else if (cd === 'DA09') {
      //DEM
      } else if (cd === 'DA10') {
      //DET
      } else if (cd === 'DA11') {
      //INVOICE 권한
      } else if (cd === 'DA12') {
      //SURRENDER D/O 확인
      } else if (cd === 'DA13') {
      //UPLOAD FLAT FILE
      } else if (cd === 'DA14') {
      //세금계산서
        myRequest.getIssDtm({ type: 'B', blNo: this.linkParams.blNo }).then(res => {
          const date = res.data ? this.changeDatePattern(res.data, '.') : '\n' + this.$t('msg.CMBA380.033')
          this.$ekmtcCommon.alertDefault('- ' + this.$t('msg.CMBA380.002') + ' : ' + date)
        })
      } else if (cd === 'DA15') {
      //MOR
      } else if (cd === 'DA16') {
      //STR
      } else if (cd === 'DA17') {
      //OFC
      }
    },
    async checkWebLink () {
      const msgHtml = []
      const cd = this.linkParams.reqBizCatCd
      if (cd === 'RE01') {
        //Booking Cancel - 선적 취소 요청서
        if (this.getDelegateYn('BL', '40')) {
          await myRequest.getRsnCont({ reqRno: this.linkParams.refRno }).then(res => {
            if (res.data) {
              this.$ekmtcCommon.alertDefault(res.data.reqRsnCont)
            }
          })
        } else {
          this.popupParams.reqBizCatCd = 'RE01'
          this.popupParams.reqRno = this.linkParams.refRno
          this.openPopup('MyRequestDocPop')
        }
      } else if (cd === 'RE02') {
        //Shuttle - 컨테이너 셔틀 요청서
        if (this.getDelegateYn('BL', '39')) {
          const data = await this.getDocSimapleInfo('02')
          if (data != null) {
            const docVo = data.dangerDocSimple
            const conList = data.docContainnerList
            msgHtml.push('<div style="text-align:left;">')
            msgHtml.push('- ' + this.$t('msg.CMBA380.007') + ' :')
            const conhHtml = []
            conList.forEach((obj, idx) => {
              conhHtml.push('&nbsp;&nbsp;&nbsp;' + obj.cntrNo + ' / ' + obj.bfTrmlCd + ' → ' + obj.updtTrmlCd)
              if (idx !== (conList.length - 1)) conhHtml.push(' \n ')
            })
            msgHtml.push(conhHtml.join(''))
            msgHtml.push('- ' + this.$t('msg.CMBA380.009') + ' : ' + (docVo.reqRsnCont || ''))
            msgHtml.push('</div>')
          }
        } else {
          this.popupParams.reqBizCatCd = 'RE02'
          this.popupParams.reqRno = this.linkParams.refRno
          this.openPopup('MyRequestDocPop')
        }
      } else if (cd === 'RE05') {
      //Carrying in HZ (terminal) - 위험물 반입 요청
        const data = await this.getDocSimapleInfo('05', 'BK')
        if (data != null) {
          const docVo = data.dangerDocSimple
          msgHtml.push('<div style="text-align:left;">')
          msgHtml.push('- VSL/VOY : ' + docVo.vslNm + ' / ' + docVo.voyNo)
          msgHtml.push('- POL ETD : ' + this.changeDatePattern(docVo.polEtd, '.'))
          msgHtml.push('- ' + this.$t('msg.CMBA380.008') + ' : ' + docVo.polTrmlCd)
          msgHtml.push('- DG Certificate No. :')
          const docContainnerList = data.docContainnerList
          if (docContainnerList) {
            const conDgHtml = []
            docContainnerList.forEach((obj, idx) => {
              conDgHtml.push('&nbsp;&nbsp;&nbsp;' + obj.cntrNo)
            })
            msgHtml.push(conDgHtml.join('\n'))
          }
          const fileList = data.docFileList
          if (fileList) {
            const attachHtml = []
            const downloadUrl = process.env.VUE_APP_TRANS_URL
            fileList.forEach((obj, idx) => {
              let downLoadUrl = downloadUrl + '/trans/file-download?reqRno=' + obj.reqRno + '&atchFileSeq=' + obj.atchFileSeq + '&type=DG_OOG_CERTI_BK'
              attachHtml.push('<div class="flex_box"><a href="' + downLoadUrl + '" target="_blank" class="button lg mr5" style="border:0px;">')
              attachHtml.push(' <span class="btn_icon deadline"></span>' + obj.fileNm)
              attachHtml.push('</a></div>')
              if (idx !== (fileList.length - 1)) attachHtml.push(' \n ')
            })
            msgHtml.push(attachHtml.join(''))
          }
          msgHtml.push('</div>')
        }
      } else if (cd === 'RE12') {
      //Freight C/A - 운임 정정
        await myRequest.getRsnCont({ reqRno: this.linkParams.refRno }).then(res => {
          if (res.data) {
            msgHtml.push('<div style="text-align:left;">')
            msgHtml.push('- ' + this.$t('tit.CSBL400.005') + ' : ')
            msgHtml.push('&nbsp;&nbsp;&nbsp;' + res.data.reqCont)
            msgHtml.push('- ' + this.$t('msg.CMBA380.009') + ' : ')
            msgHtml.push('&nbsp;&nbsp;&nbsp;' + res.data.reqRsnCont)
            msgHtml.push('</div>')
          }
        })
      } else if (cd === 'RE14') {
      //Shipback - SHIP BACK 요청서
        this.popupParams.reqBizCatCd = 'RE14'
        this.popupParams.reqRno = this.linkParams.refRno
        this.openPopup('MyRequestDocPop')
      } else if (cd === 'RE15') {
        //COD - C.O.D 요청서
        if (this.getDelegateYn('BL', '37')) {
          const data = await this.getDocSimapleInfo('15')
          if (data != null) {
            const docVo = data.dangerDocSimple
            msgHtml.push('<div style="text-align:left;">')
            msgHtml.push('- Change of Destination')
            const from = docVo.podPlcNm ? (docVo.podPlcNm + ', ' + docVo.polCtrEnm) : ''
            const to = docVo.updtPodPlcNm ? (docVo.updtPodPlcNm + ', ' + docVo.updtPodCtrEnm) : ''
            msgHtml.push('&nbsp;&nbsp;&nbsp;From : ' + from)
            msgHtml.push('&nbsp;&nbsp;&nbsp;To : ' + to)
            msgHtml.push('- ' + this.$t('msg.CMBA380.009') + ' : ' + (docVo.reqRsnCont || ''))
            msgHtml.push('</div>')
          }
        } else {
          this.popupParams.reqBizCatCd = 'RE15'
          this.popupParams.reqRno = this.linkParams.refRno
          this.openPopup('MyRequestDocPop')
        }
      } else if (cd === 'RE16') {
        //반출요청 - 컨테이너 반출요청
        if (this.getDelegateYn('BK', '38')) {
          const data = await this.getDocSimapleInfo('16')
          if (data != null) {
            const docVo = data.dangerDocSimple
            const conList = data.docContainnerList
            msgHtml.push('<div style="text-align:left;">')
            msgHtml.push('- ' + this.$t('msg.CMBA380.010') + ' :')
            const conhHtml = []
            conList.forEach((obj, idx) => {
              conhHtml.push('&nbsp;&nbsp;&nbsp;' + obj.cntrNo + ' / ' + docVo.polTrmlCd)
              if (idx !== (conList.length - 1)) conhHtml.push(' \n ')
            })
            msgHtml.push(conhHtml.join(''))
            msgHtml.push('- ' + this.$t('msg.CMBA380.011') + ' : ' + this.changeDatePattern(conList[0].coDt, '.'))
            msgHtml.push('- ' + this.$t('msg.CMBA380.012') + ' : ' + (docVo.reqRsnCont || ''))
            msgHtml.push('</div>')
          }
        } else {
          this.popupParams.reqBizCatCd = 'RE16'
          this.popupParams.reqRno = this.linkParams.refRno
          this.openPopup('MyRequestDocPop')
        }
      } else if (cd === 'RE17') {
        //컨테이너 번호 변경
        if (this.getDelegateYn('BL', '33')) {
          const data = await this.getDocSimapleInfo('17')
          if (data != null) {
            const docVo = data.dangerDocSimple
            const conList = data.docContainnerList
            msgHtml.push('<div style="text-align:left;">')
            msgHtml.push('- ' + this.$t('msg.CMBA380.032') + ' :')
            const conhHtml = []
            conList.forEach((obj, idx) => {
              conhHtml.push('&nbsp;&nbsp;&nbsp;' + obj.rmk + ' ▶ ' + obj.cntrNo)
              if (idx !== (conList.length - 1)) conhHtml.push(' \n ')
            })
            msgHtml.push(conhHtml.join(''))
            msgHtml.push('</div>')
          }
        } else {
          this.popupParams.reqBizCatCd = 'RE17'
          this.popupParams.reqRno = this.linkParams.refRno
          this.openPopup('MyRequestDocPop')
        }
      } else if (cd === 'RE06') {
        const TH = this
        let blYn = ''
        await myRequest.findblYn(this.linkParams.blNo).then(res => {
           if (res.data) {
            blYn = res.data
           }
        })
        //비엘이 있는 경우, 비엘 화면으로 이동, SR만 있는 경우 SR화면으로 이동.
        this.$ekmtcCommon.confirmCallBack(this.$t('msg.CSBL250.020'), () => {
          TH.fnLouter('bl', 'P', { tab: 'bl', bkgNo: this.linkParams.bkgNo, blNo: blYn === 'Y' ? this.linkParams.blNo : '', srRno: this.linkParams.blNo })
        })
      } else if (cd === 'RE22' || cd === 'RE23') {
        this.$router.push({ name: 'booking-edit', params: { bkgNo: this.linkParams.bkgNo, srRno: this.linkParams.blNo } }).catch(() => {})
      }

      if (msgHtml.length > 0) {
        this.$ekmtcCommon.alertDefault(msgHtml.join('\n'))
      }
    },
    async getDocSimapleInfo (reqCatCd, kind) {
      const params = {
        reqCatCd: reqCatCd,
        kind: kind,
        bkgNo: this.linkParams.bkgNo,
        reqRno: this.linkParams.refRno
      }
      let result = null
      await docs.getDangerDocSimple(params).then(response => {
          if (response.status === 200) {
            result = response.data
          }
      })
      return result
    },
    async checkEtcLink () {
      const cd = this.linkParams.reqBizCatCd
      if (cd === 'CE01') {
        const TH = this
        this.$ekmtcCommon.confirmCallBack(this.$t('msg.CMBA380.013'), () => {
          TH.fnLouter('bl-certificate', 'P', { tab: 'bl-certificate', bkgNo: this.linkParams.bkgNo, blNo: this.linkParams.blNo })
        })
      } else if (cd === 'MI01') {
        const refRno = this.linkParams.refRno.split(';')
        const cstNo = refRno[0]
        const occrDt = refRno[1]
        const seq = refRno[2]
        await myRequest.getSimpleMileage({ cstNo: cstNo, occrDt: occrDt, seq: seq }).then(res => {
          if (res.data) {
            const mileageVo = res.data
            const msgHtml = []
            msgHtml.push('<div style="text-align:left;">')
            msgHtml.push('- ' + this.$t('msg.MYIN030T010.029') + ' : ' + this.$ekmtcCommon.changeNumberFormat(mileageVo.mlgAmt, { isComma: true }))
            msgHtml.push('- ' + this.$t('msg.CMBA380.014'))
            msgHtml.push('&nbsp;&nbsp;&nbsp; ' + this.$t('msg.KMTC413.003') + ' : ' + mileageVo.cstPicNm)
            msgHtml.push('&nbsp;&nbsp;&nbsp; ' + this.$t('msg.KMTC413.005') + ' : ' + mileageVo.cstPicMbpNo)
            msgHtml.push('&nbsp;&nbsp;&nbsp; ' + this.$t('msg.KMTC413.004') + ' : ' + mileageVo.cstPicEmlAddr)
            msgHtml.push('</div>')
            this.$ekmtcCommon.alertDefault(msgHtml.join('\n'))
          }
        })
      } else if (cd === 'CA01') {
        const caNo = this.linkParams.refRno.split(';')[1]
        this.popupParams.blNo = this.linkParams.blNo
        this.popupParams.caNo = caNo
        this.popupParams.isFlag = true
        this.openPopup('ShippingLogBLDetailPop')
      } else if (cd === 'FR01') {
        const TH = this
        let eiCatCd = ''
        if (this.linkParams.porCtrCd === this.memberDetail.userCtrCd) { // 수출
          eiCatCd = 'O'
        } else if (this.linkParams.dlyCtrCd === this.memberDetail.userCtrCd) { // 수입
          eiCatCd = 'I'
        }
        this.$ekmtcCommon.confirmCallBack(this.$t('msg.CMBA380.015'), () => {
          TH.fnLouter('FreeTimeRequest', 'Q', { dtKnd: 'BL', blNo: this.linkParams.blNo, num: this.linkParams.blNo, eiCatCd: eiCatCd })
        })
      } else if (cd === 'RA01') {
        await myRequest.getFreightSimple({ frtAppNo: this.linkParams.refRno, flagYn: 'N' }).then(res => {
          if (res.data) {
            const rvo = res.data
            const endDt = rvo.endDt
            const msgHtml = []

            const newFrtAppNoMsg = rvo.apvStsCd === '03' ? rvo.frtAppNo : this.$t('msg.CMBA380.019')
            msgHtml.push('<div style="text-align:left;">')
            msgHtml.push('- ' + this.$t('msg.CMBA380.016') + ' : ' + rvo.refFrtAppNo)
            msgHtml.push('- ' + this.$t('msg.CMBA380.017') + ' : ' + this.changeDatePattern(rvo.refStrDt, '.') + '~' + this.changeDatePattern(rvo.refEndDt, '.'))
            msgHtml.push('- ' + this.$t('msg.CMBA380.018') + ' : ' + newFrtAppNoMsg)
            if (endDt) {
              msgHtml.push('- ' + this.$t('msg.CMBA380.020') + ' : ' + this.changeDatePattern(rvo.strDt, '.') + '~' + this.changeDatePattern(endDt, '.'))
              msgHtml.push('</div>')
            } else {
              const reqStrDt = this.$ekmtcCommon.getStrToDate(endDt)
              reqStrDt.setDate(reqStrDt.getDate() + 1)

              let chgReqMonth = reqStrDt.getMonth() + 1
              let chgReqDate = reqStrDt.getDate()

              chgReqMonth = this.getChgNum(chgReqMonth)
              chgReqDate = this.getChgNum(chgReqDate)

              const reqEndDt = this.$ekmtcCommon.getStrToDate(endDt)
              reqEndDt.setMonth(reqStrDt.getMonth() + 3)

              let chgReqEndMonth = reqEndDt.getMonth() + 1
              let chgReqEndDate = reqEndDt.getDate()

              chgReqEndMonth = this.getChgNum(chgReqEndMonth)
              chgReqEndDate = this.getChgNum(chgReqEndDate)

              const chgStrDt = reqStrDt.getFullYear() + '' + chgReqMonth + '' + chgReqDate
              const chgEndDt = reqEndDt.getFullYear() + '' + chgReqEndMonth + '' + chgReqEndDate
              msgHtml.push('- ' + this.$t('msg.CMBA380.020') + ' : ' + this.changeDatePattern(chgStrDt, '.') + '~' + this.changeDatePattern(chgEndDt, '.'))
              msgHtml.push('</div>')
            }
            this.$ekmtcCommon.alertDefault(msgHtml.join('\n'))
          }
        })
      } else if (cd === 'RA02' || cd === 'RA03') {
        await myRequest.getFreightSimple({ frtAppNo: this.linkParams.refRno, flagYn: 'Y' }).then(res => {
          if (res.data) {
            const rvo = res.data
            const msgHtml = []
            msgHtml.push('<div style="text-align:left;">')
            msgHtml.push('- R/A No : ' + this.linkParams.refRno)
            msgHtml.push('- ' + this.$t('msg.CMBA380.021') + ' : ' + rvo.polPortCd + '/' + rvo.podPortCd)
            msgHtml.push('- SIZE/TYPE : ')
            const subMsgHtml = []
            const rateMsgHtml = []
            if (rvo.subList.length > 0) {
                rvo.subList.forEach(obj => {
                    subMsgHtml.push('&nbsp;&nbsp;&nbsp;' + obj.cntrSzCd + '/' + obj.cntrTypCd)
                    rateMsgHtml.push(obj.reqRate)
                })
            }
            if (subMsgHtml.toString().length > 0) {
              msgHtml.push(subMsgHtml.join('\n'))
            }
            msgHtml.push('- ' + this.$t('msg.CMBA380.022') + ' : ')
            if (subMsgHtml.toString().length > 0) {
              msgHtml.push(rateMsgHtml.join('\n'))
            }
            msgHtml.push('</div>')
            this.$ekmtcCommon.alertDefault(msgHtml.join('\n'))
          }
        })
      } else if (cd === 'WA02') {
        this.popupParams.bkgNo = this.linkParams.bkgNo
        this.openPopup('BookingCancelFeePop')
      }
    },
    fnLouter (name, type, query) {
      this.setCurrentData()
      if (type === 'Q') {
        this.$router.push({ name, query }).catch(() => {})
      } else {
        this.$router.push({ name, params: query }).catch(() => {})
      }
    },
    fnLink (e, cd, bkgNo, blNo, refRno, porCtrCd, dlyCtrCd) {
      e.preventDefault()
      this.linkParams.reqBizCatCd = cd
      this.linkParams.bkgNo = bkgNo
      this.linkParams.blNo = blNo
      this.linkParams.refRno = refRno
      this.linkParams.porCtrCd = porCtrCd
      this.linkParams.dlyCtrCd = dlyCtrCd

      // GA dataLayer 추가
      window.dataLayer.push({
        event: 'set_popup_info',
        popup_booking_no: bkgNo,
        popup_bl_no: blNo
      })

      let isFlag = false
      if (cd.indexOf('DA') > -1) {
        this.checkWebDataLink()
      } else if (cd.indexOf('RE') > -1) {
        this.checkWebLink()
      } else {
        this.checkEtcLink()
      }
    },
    fnCancel (e, bizCatCd, refRno) {
      e.preventDefault()
      let reqBizCatType = ''
      if (bizCatCd === 'DA05') {
        this.returnReqCancel(refRno)
      } else {
        this.$ekmtcCommon.confirmCallBack(this.$t('msg.CMBA380.027'), () => {
          const arrReq = ['DA', 'RE', 'CE', 'MI', 'CA', 'FR', 'RA', 'WA']
          arrReq.some((obj, idx) => {
            if (bizCatCd.indexOf(obj) > -1) {
              reqBizCatType = obj
              return true
            }
          })

          myRequest.putMyRequest({ reqRno: refRno, reqBizCatType: reqBizCatType }).then(async res => {
            if (res.data.resultCode === 'SUCC') {
              await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CMBA380.028') })
            } else {
              await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CMBA380.040') })
            }
            this.search()
          })
        })
      }
    },
    async returnReqCancel (refRno) {
      await myRequest.findRequestDetail(refRno).then(async res => {
        if (res.headers.respcode === 'C0000') {
          const data = res.data

          if (this.$ekmtcCommon.isNotEmpty(data)) {
            const invNo = data.invIssNo || ''
            const apvStsCd = data.apvStsCd || ''
            const invUser = data.invUser || ''
            const reqRno = data.reqRno || ''
            const loginUser = this.auth.userId

            if (this.$ekmtcCommon.isEmpty(invNo)) {
              this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.049')) //컨테이너를 확인 하세요.
              return
            }

            if (invUser !== loginUser) {
              this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.038')) //권한이 없습니다
              return
            }

            // 03 : accepted
            // 04 : rejected
            // 10 : cancelled
            if (apvStsCd === '03') {
              this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.050')) //이미 승인 처리 된 요청 건에 대해서는 취소가 불가합니다.
              return
            }

            if (apvStsCd === '04') {
              this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.051')) //이미 Rejected 처리 된 요청 건에 대해서는 취소가 불가합니다.
              return
            }

            if (apvStsCd === '10') {
              this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.052')) //이미 Cancelled 처리 된 요청 건에 대해서는 취소가 불가합니다.
              return
            }

            const confirmMessage = this.$t('msg.ONIM070G010.053') + '\n' + '(Invoice No.' + invNo + ')'
            if (await this.$ekmtcCommon.asyncAlertMessage({ message: confirmMessage, useConfirmBtn: true })) {
              let formData = {
                invNo: invNo,
                reqRno: reqRno
              }

              let resultData = await containerReturn.returnChangeCancel(formData)
              let respCode = resultData.headers.respcode

              if (respCode === 'C0000') {
                this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.054')) //Cancel successfully!
                this.search()
              } else {
                this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.055')) //Cancel fail!
              }
            }
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('js.common.011'))
          }
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.011'))
        }
      })
    },
    async fnUserPop (e, bizCatCd, apvUno, apvStsNm, porPlcCd, blNo, refRno, ntcCd) {
      const cd = bizCatCd
      const rno = refRno
      let raNo = ''

      if (cd === 'RA01') { // 운임연장
        await myRequest.getFreightSimple({ frtAppNo: rno, flagYn: 'N' }).then(res => {
          if (res.data) {
            const rvo = res.data

            // const newFrtAppNoMsg = rvo.apvStsCd === '03' ? rvo.frtAppNo : this.$t('msg.CMBA380.019')
            // msgHtml.push('- ' + this.$t('msg.CMBA380.016') + ' : ' + rvo.refFrtAppNo)  // 기존
            // msgHtml.push('- ' + this.$t('msg.CMBA380.018') + ' : ' + newFrtAppNoMsg)   // 신규

            raNo = rvo.apvStsCd === '03' ? rvo.frtAppNo : rvo.refFrtAppNo
          }
        })
      } else if (cd === 'RA02' || cd === 'RA03') { // 운임문의, 운임네고
          raNo = refRno
      }

      e.preventDefault()
      this.popupParams = {
        bizCatCd: bizCatCd,
        apvUno: apvUno,
        apvStsNm: apvStsNm,
        porPlcCd: porPlcCd,
        blNo: blNo,
        raNo: raNo,
        ntcCd: ntcCd
      }
      this.openPopup('MyRequestUserPop')
    },
    openPopup (compNm) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose()
      }

      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.my_request_doc')
    },
    closePopup (obj) {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.my_request_doc')
    },
    showTooltip (e) {
      const el = e.target
      const parentEl = el.parentElement.parentElement
      parentEl.querySelector('.tooltip_wrap').style.display = 'inline-block'
    },
    hideTooltip (e) {
      const el = e.target
      const parentEl = el.parentElement.parentElement
      parentEl.querySelector('.tooltip_wrap').style.display = 'none'
    },
    copyTextCallback (grid, itemIndex, column, value) {
      if (column.name === 'reqDate') {
        var reqDate = gridView.getValue(itemIndex, 'reqDate')

        if (reqDate) {
          var year = reqDate.getFullYear()
          var month = reqDate.getMonth() + 1
          var date = reqDate.getDate()
          var hour = reqDate.getHours()
          var time = reqDate.getMinutes()

          if (month < 10) month = '0' + month
          if (date < 10) date = '0' + date
          if (hour < 10) hour = '0' + hour
          if (time < 10) time = '0' + time

          return year + '.' + month + '.' + date + ' ' + hour + ':' + time
        }
      }

      if (column.name === 'apvDate') {
        const reqBizCatCd = gridView.getValue(itemIndex, 'reqBizCatCd') || ''
        let chgDate = ''
        let apvStsNm = gridView.getValue(itemIndex, 'apvStsNm') || ''
        apvStsNm = apvStsNm.replace(/\s/gi, '')
        if ((apvStsNm === '승인' || apvStsNm === '거절' || apvStsNm === '취소' || apvStsNm === '터미널요청완료')) {
          const apvDate = gridView.getValue(itemIndex, 'apvDate') || ''
          chgDate = vmApp.changeDatePattern(apvDate)
        } else {
          let reqDate = gridView.getValue(itemIndex, 'reqDate') || ''
          if (reqDate !== '') {
            let year = reqDate.getFullYear()
            let month = reqDate.getMonth() + 1
            let date = reqDate.getDate()
            let hour = reqDate.getHours()
            let time = reqDate.getMinutes()
            if (month < 10) month = '0' + month
            if (date < 10) date = '0' + date
            if (hour < 10) hour = '0' + hour
            if (time < 10) time = '0' + time
            reqDate = year + '' + month + '' + date + '' + hour + '' + time
            vmApp.timeList.some((obj, idx) => {
              if (reqBizCatCd.indexOf(obj.cd) > -1 && reqBizCatCd.indexOf('WA01') < 0 && obj.dsc) {
                const reqStrDt = vmApp.$ekmtcCommon.getStrToDate(reqDate)
                reqStrDt.setMinutes(reqStrDt.getMinutes() + Number(obj.dsc))

                let chgHour = reqStrDt.getHours()
                let chgMinutes = reqStrDt.getMinutes()

                chgHour = vmApp.getChgNum(chgHour)
                chgMinutes = vmApp.getChgNum(chgMinutes)

                if (vmApp.auth.serviceLang === 'KOR') {
                  chgDate = chgHour + '시 ' + chgMinutes + '분'
                } else {
                  chgDate = chgHour + ': ' + chgMinutes
                }
                return true
              }
            })
          }
        }
        return chgDate
      }

      if (column.name === 'apvNm') {
        return '연락처'
      }
    },
    exlDownload () {
      const curDt = moment().format('YYYYMMDD')
      gridView.exportGrid({
        type: 'excel',
        target: 'local',
        textCallback: function (index, column, value) {
          if (column === 'reqDate') {
            const reqDate = gridView.getValue(itemIndex, 'reqDate')

            if (reqDate) {
              let year = reqDate.getFullYear()
              let month = reqDate.getMonth() + 1
              let date = reqDate.getDate()
              let hour = reqDate.getHours()
              let time = reqDate.getMinutes()

              if (month < 10) month = '0' + month
              if (date < 10) date = '0' + date
              if (hour < 10) hour = '0' + hour
              if (time < 10) time = '0' + time

              return year + '.' + month + '.' + date + ' ' + hour + ':' + time
            }
          } else {
            return value
          }
        },
        documentTitle: { //제목
          message: this.$t('menu.MENU03.030'), // 나의 요청사항
          visible: true,
          spaceTop: 1,
          spaceBottom: 1,
          height: 60,
          styleName: 'excel-export-title-style'
        },
//        applyDynamicStyles: true,
        exportTemplate: true,
//        allColumns: true,
        pagingAllItems: true,
        fileName: `EKMTC_${this.$t('menu.MENU03.030')}_${curDt}.xlsx`,
        showProgress: false,
        progressMessage: this.$t('msg.ADD20220219.012'), // 엑셀 Export중입니다.
        indicator: 'default',
        header: 'default',
        footer: 'default',
        done: function () {
          console.log('엑셀 다운로드 완료')
        }
      })
    } /*
    exlDownload () { // 그리드 엑셀 다운로드
      let title = `${this.$t('menu.MENU03.010')}_${this.$t('msg.ONEX010T010.002')}` // 진행중인업무_수출
      this.$ekmtcCommon.gridExportExcel(gridView, title)
    } */
  }
}

</script>

<style>
.multi-line-css {
  white-space: pre;
  text-align: center !important;
}
</style>
